

(function($, window, document) {
  var mobileNavBtn = $('.menu__list-trigger');


  mobileNavBtn.click(function(e) {
    e.preventDefault();
    var $this = $(this);
    $('.menu__list').removeClass('menu__list--open');
    $this.next('.menu__list').addClass('menu__list--open')
  });


})(window.jQuery, window, document);
