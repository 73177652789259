(function($, window, document) {
  // var closeTrigger = $(".profile-overlay, .profile-modal-close");
  // var overlay = $('.profile-overlay');

  // $(".profile-img").click(function() {
  //   var $this = $(this);
  //   overlay.addClass("visible");
  //   $(".profile-description").removeClass("visible");
  //   $this.next(".profile-description").addClass("visible");
  // });

  // closeTrigger.click(function() {
  //   overlay.removeClass("visible");
  //   $(".profile-description").removeClass("visible");
  // });

})(window.jQuery, window, document);
