(function($, window, document) {
	$(".btn__read-more").click(function() {
		$("html, body").animate(
			{
				scrollTop: $("#intro-title").offset().top
			},
			500
		);
	});
})(window.jQuery, window, document);

