(function($, window, document) {
  var q = $(".faq__question");
  var a = $(".faq__answer");
  var t = $(".faq__toggle");

  $(".faq__question").click(function() {
    var $this = $(this);

    if (!$this.hasClass("active")) {
      q.removeClass("active");
      a.removeClass("active");
      t.removeClass("active");
      $this.addClass("active");
      $this.next(".faq__answer").addClass("active");
      $this.parent().find(".faq__toggle").addClass("active");
    } else {
      q.removeClass("active");
      a.removeClass("active");
      t.removeClass("active");
    }
  });
})(window.jQuery, window, document);
