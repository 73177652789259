(function($, window, document) {
    var owl = $(".owl-carousel");

    owl.owlCarousel({
        loop: true,
        margin: 24,
        dots: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    });

    // Custom Navigation Events
    $(".btn--next").click(function() {

        owl.trigger("next.owl.carousel");
    });
    $(".btn--prev").click(function() {
        owl.trigger("prev.owl.carousel");
    });
})(window.jQuery, window, document);
