(function($, window, document) {


  $("#avg").click(function() {
    var $this = $(this);
    if ($this[0].checked) {
      $('.js-form-submit').attr('disabled', false);
    } else {
      $('.js-form-submit').attr('disabled', 'disabled');
    }
  });

  $('#functie_overig').hide();

  var functie = $( "#functie" );
  functie.change(function() {
    if (functie.val() == 'anders'){
      $('#functie_overig').show();
    }
  });

 
})(window.jQuery, window, document);
