

(function($, window, document) {
  var menuBtn = $('.menu__btn');
  var openBtn = $('.menu__btn--inactive');
  var closeBtn = $('.menu__btn--active');
  var navOverlay = $('.nav__overlay');
  var body = $('body');

  var closeNav = function(){
    menuBtn.removeClass('menu__btn--hidden menu__btn--visible')
    closeBtn.addClass('menu__btn--hidden');
    openBtn.addClass('menu__btn--visible');
    navOverlay.removeClass('nav__overlay--visible');
    body.removeClass('no-scroll'); 
  }



  openBtn.click(function(e) {
    e.preventDefault();
    window.scrollTo(0, 0);
    menuBtn.removeClass('menu__btn--hidden menu__btn--visible')
    openBtn.addClass('menu__btn--hidden');
    closeBtn.addClass('menu__btn--visible');
    navOverlay.addClass('nav__overlay--visible');
    body.addClass('no-scroll');
  });

  closeBtn.click(function() {
    closeNav();
  });



  $('.js-tab--trigger').click(function(){

    var $this = $(this);
    var tab_id = $this.attr('data-tab-id');
    
    $('.tab-pane').removeClass('show active');
    $('#' + tab_id).addClass('show active');
  })

})(window.jQuery, window, document);
